body, html {
  height: 100%;
  margin: 0;
  font-family: Arial;
}

.tab-section {
  height: 100%;
  padding-top: 5em;
}

.tablink {
  color: #fff;
  float: left;
  cursor: pointer;
  background-color: #555;
  border: none;
  outline: none;
  width: 25%;
  padding: 14px 16px;
  font-size: 17px;
}

.tablink:hover {
  background-color: #777;
}

.tabcontent {
  color: #fff;
  height: 100%;
  padding: 100px 20px;
  display: none;
}

#Home {
  background-color: red;
}

#News {
  background-color: green;
}

#Contact {
  background-color: #00f;
}

#About {
  background-color: orange;
}

.cart {
  color: #fff;
  background-color: #555;
  border-radius: 2px;
  margin-left: 25px;
  padding: 15px 15px 0 5px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  right: 0;
}

.cart:hover {
  background: #817d7d;
}

.cart .badge {
  color: #fff;
  background: #32cd32;
  border-radius: 50%;
  padding: 5px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
}

.modal {
  z-index: 1;
  background-color: #0006;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.modal-header {
  color: #fff;
  background-color: #000;
  padding: 2px 16px;
}

.modal-body {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px 16px;
  display: flex;
}

.modal-footer {
  color: #fff;
  background-color: #000;
  justify-content: space-between;
  padding: 2px 16px;
  display: flex;
}

.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  width: 80%;
  margin: 15% auto;
  animation-name: animatetop;
  animation-duration: .4s;
  position: relative;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}
/*# sourceMappingURL=index.1ae5491d.css.map */
